import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Wafer inspection equipment`}</h4>
    <p>{`Even the tiniest imperfections can cause serious problems during chip production. Major types of wafer inspection tools include film and wafer measuring tools, critical dimensions measuring tools, defect inspection tools, general-purpose microscopes, and structural inspection tools.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      